import ScrollTop from "./components/common/ScrollTop";
import Router from "./components/routes";
import { useEffect } from "react";
import 'aos/dist/aos.css';
import Aos from "aos";

function App() {
  useEffect(() => {
    Aos.init({
      duration: 1200,
      once: true,
    });
  }, []);
  return (
    <>
      <Router />
      <ScrollTop />
    </>
  );
}

export default App;
