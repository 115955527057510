import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";

function Cancellation() {
    return(
        <>
          <div id="main-wrapper" className="boxed">
              <Header />
              <section class="page-header page-header bg-tabnav">
               <div class="container">
                 <div class="row align-items-center">
                   <div class="col-md-8">
                      <h1>Cancellation and Refund Policy</h1>
                   </div>
                   <div class="col-md-4">
                      <ul class="breadcrumb justify-content-start justify-content-md-end mb-0">
                         <li><Link to="/">Home</Link></li>
                         <li className="active">Cancellation and Refund Policy</li>
                      </ul>
                   </div>
                 </div>
               </div>
              </section>
              <div id="content">
                 <div class="container">
                    <div class="bg-white shadow-md rounded p-4">
                       <p>XINE TECHNOLOGY SOLUTIONS PRIVATE LIMITED believes in helping its customers as far as possible, and has therefore a liberal cancellation policy. Under this policy:</p>
                       <ul>
                         <li className="li-item">Cancellations will be considered only if the request is made within 7 days of placing the order. However, the cancellation request may not be entertained if the orders have been communicated to the vendors/merchants and they have initiated the process of shipping them.</li>
                         <li className="li-item">XINE TECHNOLOGY SOLUTIONS PRIVATE LIMITED does not accept cancellation requests for perishable items like flowers, eatables etc. However, refund/replacement can be made if the customer establishes that the quality of product delivered is not good.</li>
                         <li className="li-item">In case of receipt of damaged or defective items please report the same to our Customer Service team. The request will, however, be entertained once the merchant has checked and determined the same at his own end. This should be reported within 7 days of receipt of the products.</li>
                         <li className="li-item">In case you feel that the product received is not as shown on the site or as per your expectations, you must bring it to the notice of our customer service within 7 days of receiving the product. The Customer Service Team after looking into your complaint will take an appropriate decision.</li>
                         <li className="li-item">In case of complaints regarding products that come with a warranty from manufacturers, please refer the issue to them.</li>
                         <li className="li-item">In case of any Refunds approved by the XINE TECHNOLOGY SOLUTIONS PRIVATE LIMITED, it’ll take 6-8 days for the refund to be processed to the end customer.</li>
                       </ul>
                    </div>
                 </div>
              </div>
              <Footer />
          </div>
        </>
    )
}

export default Cancellation;