import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";

function Support() {
    return(
        <>
          <div id="main-wrapper" className="boxed">
              <Header />
              <section class="page-header page-header bg-tabnav">
               <div class="container">
                 <div class="row align-items-center">
                   <div class="col-md-8">
                      <h1>Support</h1>
                   </div>
                   <div class="col-md-4">
                      <ul class="breadcrumb justify-content-start justify-content-md-end mb-0">
                         <li><Link to="/">Home</Link></li>
                         <li className="active">Support</li>
                      </ul>
                   </div>
                 </div>
               </div>
              </section>
              <div id="content">
                 <div class="container">
                   <div class="row g-4">
                     <div class="col-lg-8">
                      <div class="bg-white shadow-md rounded p-4">
                        <h2 class="text-6 mb-4">Send a Request</h2>
                        <hr class="mx-n4 mb-4" />
                        <p class="text-3">Please fill out the form below. we will get back to you within a couple of hours.</p>
                        <form id="recharge-bill" method="post">
                         <div class="mb-3">
                          <label class="form-label" for="subject">Subject</label>
                          <select class="form-select" id="subject" required="">
                            <option value="">Select Your Subject</option>
                            <option>eSIM</option>
                            <option>Booking</option>
                            <option>Account</option>
                            <option>Payment</option>
                            <option>Other</option>
                          </select>
                         </div>
                         <div class="mb-3">
                           <label class="form-label" for="yourName">Your Name</label>
                           <input type="text" class="form-control" id="yourName" required placeholder="Enter Your Name" />
                         </div>
                         <div class="mb-3">
                           <label class="form-label" for="yourEmail">Your Email</label>
                           <input type="email" class="form-control" id="yourEmail" required placeholder="Enter Email Id" />
                         </div>
                         <div class="mb-3">
                           <label class="form-label" for="mobileNumber">Mobile Number</label>
                           <input type="text" class="form-control" data-bv-field="number" id="mobileNumber" required placeholder="Enter Mobile Number" />
                         </div>
                         <div class="mb-3">
                           <label class="form-label" for="yourProblem">Your Query</label>
                           <textarea class="form-control" rows="5" id="yourProblem" required placeholder="Specify your query"></textarea>
                         </div>
                         <button class="btn btn-primary" type="submit">Submit</button>
                        </form>
                      </div>
                     </div>
                 </div>
               </div>
              </div>
              <Footer />
          </div>
        </>
    )
}

export default Support;