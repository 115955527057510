import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";

function Home() {
  const handleClick = () => {
     alert("Button Clicked...");
  };
  return(
    <>
      <div id="main-wrapper" className="boxed">
        <Header />
        <div id="content"> 
          <div className="bg-tabnav">
            <div className="container">
              <ul className="nav secondary-nav tabnavul" id="services" role="tablist">
                <li className="nav-item" role="presentation">
                   <button className="nav-link active" id="esim-tab" data-bs-toggle="tab" data-bs-target="#esim" type="button" role="tab" aria-controls="esim" aria-selected="true"><span><i className="fas fa-sim-card"></i></span> eSIM</button>
                </li>  
                <li className="nav-item" role="presentation">
                   <button className="nav-link"  id="flights-tab" data-bs-toggle="tab" data-bs-target="#flights" type="button" role="tab" aria-controls="flights" aria-selected="false"><span><i className="fas fa-plane"></i></span> Flights</button>
                </li>   
              </ul>
            </div>
          </div>
          <section className="container">
            <div className="tab-content" id="myTabContent">
            {/*ESIM Tab Start*/}
            <div class="tab-pane fade show active" id="esim" role="tabpanel" aria-labelledby="esim-tab">
            <div className="bg-white shadow-md rounded p-4">
              <div className="row">
                 <div className="col-lg-12">
                    <div id="content">
                      <div className="hero-wrap">
                        <div className="hero-mask opacity-9 bg-white"></div>
                        <div className="hero-content d-flex min-vh-25">
                          <div className="container my-auto py-5">
                             <div className="py-5">
                                <h2 className="text-10 text-center fw-600 mb-2">Shop eSIM Now</h2>
                                <p className="text-5 text-center mb-4 mb-md-5">Which country are you travelling ?</p>
                                    <div className="row justify-content-center">
                                      <div className="col-sm-10 col-lg-8 col-xl-7">
                                        <div className="row g-4 g-lg-0 search-input-2">
                                          <div className="col-lg-9 col-xl-10 form-group">
                                            <input type="tel" className="form-control form-control-lg shadow" id="mobilenumber" required placeholder="Search for Countries"/>
                                          </div>
                                          <div className="col-lg-3 col-xl-2 form-group d-grid"><button className="btn btn-primary shadow btn-lg" onClick={(e) => handleClick()}><i className="fas fa-arrow-right"></i></button></div>
                                        </div>
                                      </div>
                                    </div>
                             </div>
                          </div>
                        </div>
                      </div>
                      <section class="section bg-white">
                         <div class="container my-sm-5">
                            <h2 class="text-9 text-center">How does it work?</h2>
                            <div class="row">
                              <div class="col-md-4">
                                <div class="featured-box style-4">
                                   <div class="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle"> <i class="fas fa-credit-card"></i> </div>
                                   <h3 class="text-5 fw-400 mb-3">Purchase</h3>
                                   <p class="text-3">Select the desired country and choose your plan. Once purchased you will receive an email with QR Code for eSIM Activation</p>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="featured-box style-4">
                                   <div class="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle"> <i class="fas fa-qrcode"></i> </div>
                                   <h3 class="text-5 fw-400 mb-3">Scan the QR Code</h3>
                                   <p class="text-3">Open the QR Code Scanner on your phone and Scan the QR Code to activate the eSIM.</p>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="featured-box style-4">
                                   <div class="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle"> <i class="fas fa-toggle-on"></i> </div>
                                   <h3 class="text-5 fw-400 mb-3">Activate</h3>
                                   <p class="text-3">Follow the onscreen instructions to activate the eSIM.</p>
                                </div>
                              </div>
                            </div>
                         </div>
                      </section>
                    </div>
                 </div>
              </div> 
              <section class="section bg-white">
                <div class="container">
                  <h2 class="text-9 text-center">Valid for 150+ Countries</h2>
                  <p class="lead text-center mb-5">250+ Operators</p>
                  <div class="row">
                    <div class="col-12 text-center"> <img class="img-fluid" src="images/world-map.png" alt="world-map" /> </div>
                  </div>
                </div>
              </section>  
            </div>
            </div>
            {/* ESIM Tab End */}
            {/* Flights Tab Start */}
            <div class="tab-pane fade" id="flights" role="tabpanel" aria-labelledby="flights-tab">
              <div className="bg-white shadow-md rounded p-4">
              <h2 class="text-4 mb-3">Book Domestic and International Flights</h2>
          <form id="bookingFlight" method="post">
            <div class="mb-3">
              <div class="form-check form-check-inline">
                <input id="oneway" name="flight-trip" class="form-check-input" checked="" required type="radio" />
                <label class="form-check-label" for="oneway">One Way</label>
              </div>
              <div class="form-check form-check-inline">
                <input id="roundtrip" name="flight-trip" class="form-check-input" required type="radio" />
                <label class="form-check-label" for="roundtrip">Round Trip</label>
              </div>
            </div>
            <div class="row g-3">
              <div class="col-md-6 col-lg-2">
                <div class="position-relative">
                  <input type="text" class="form-control" id="flightFrom" required placeholder="From" />
                  <span class="icon-inside"><i class="fas fa-map-marker-alt"></i></span> </div>
              </div>
              <div class="col-md-6 col-lg-2">
                <div class="position-relative">
                  <input type="text" class="form-control" id="flightTo" required placeholder="To" />
                  <span class="icon-inside"><i class="fas fa-map-marker-alt"></i></span> </div>
              </div>
              <div class="col-md-6 col-lg-2">
                <div class="position-relative">
                  <input id="flightDepart" type="text" class="form-control" required placeholder="Depart Date" />
                  <span class="icon-inside"><i class="far fa-calendar-alt"></i></span> </div>
              </div>
              <div class="col-md-6 col-lg-2">
                <div class="position-relative">
                  <input id="flightReturn" type="text" class="form-control" required placeholder="Return Date" />
                  <span class="icon-inside"><i class="far fa-calendar-alt"></i></span> </div>
              </div>
              <div class="col-md-6 col-lg-2">
                <div class="travellers-class">
                  <input type="text" id="flightTravellersClass" class="travellers-class-input form-control" name="flight-travellers-class" placeholder="Travellers, Class" readonly required onkeypress="return false;" />
                  <span class="icon-inside"><i class="fas fa-caret-down"></i></span>
                  <div class="travellers-dropdown">
                    <div class="row align-items-center">
                      <div class="col-sm-7">
                        <p class="mb-sm-0">Adults <small class="text-muted">(12+ yrs)</small></p>
                      </div>
                      <div class="col-sm-5">
                        <div class="qty input-group">
                          <div class="input-group-prepend">
                            <button type="button" class="btn bg-light-4" data-value="decrease" data-target="#flightAdult-travellers" data-toggle="spinner">-</button>
                          </div>
                          <input type="text" data-ride="spinner" id="flightAdult-travellers" class="qty-spinner form-control" value="1" readonly />
                          <div class="input-group-append">
                            <button type="button" class="btn bg-light-4" data-value="increase" data-target="#flightAdult-travellers" data-toggle="spinner">+</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="my-2" />
                    <div class="row align-items-center">
                      <div class="col-sm-7">
                        <p class="mb-sm-0">Children <small class="text-muted">(2-12 yrs)</small></p>
                      </div>
                      <div class="col-sm-5">
                        <div class="qty input-group">
                          <div class="input-group-prepend">
                            <button type="button" class="btn bg-light-4" data-value="decrease" data-target="#flightChildren-travellers" data-toggle="spinner">-</button>
                          </div>
                          <input type="text" data-ride="spinner" id="flightChildren-travellers" class="qty-spinner form-control" value="0" readonly />
                          <div class="input-group-append">
                            <button type="button" class="btn bg-light-4" data-value="increase" data-target="#flightChildren-travellers" data-toggle="spinner">+</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="my-2" />
                    <div class="row align-items-center">
                      <div class="col-sm-7">
                        <p class="mb-sm-0">Infants <small class="text-muted">(Below 2 yrs)</small></p>
                      </div>
                      <div class="col-sm-5">
                        <div class="qty input-group">
                          <div class="input-group-prepend">
                            <button type="button" class="btn bg-light-4" data-value="decrease" data-target="#flightInfants-travellers" data-toggle="spinner">-</button>
                          </div>
                          <input type="text" data-ride="spinner" id="flightInfants-travellers" class="qty-spinner form-control" value="0" readonly />
                          <div class="input-group-append">
                            <button type="button" class="btn bg-light-4" data-value="increase" data-target="#flightInfants-travellers" data-toggle="spinner">+</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="mt-2" />
                    <div class="mb-3">
                      <div class="form-check">
                        <input id="flightClassEconomic" name="flight-class" class="flight-class form-check-input" value="0" checked="" required type="radio" />
                        <label class="form-check-label" for="flightClassEconomic">Economic</label>
                      </div>
                      <div class="form-check">
                        <input id="flightClassPremiumEconomic" name="flight-class" class="flight-class form-check-input" value="1" required type="radio" />
                        <label class="form-check-label" for="flightClassPremiumEconomic">Premium Economic</label>
                      </div>
                      <div class="form-check">
                        <input id="flightClassBusiness" name="flight-class" class="flight-class form-check-input" value="2" required type="radio" />
                        <label class="form-check-label" for="flightClassBusiness">Business</label>
                      </div>
                      <div class="form-check">
                        <input id="flightClassFirstClass" name="flight-class" class="flight-class form-check-input" value="3" required type="radio" />
                        <label class="form-check-label" for="flightClassFirstClass">First Class</label>
                      </div>
                    </div>
                    <div class="d-grid">
                      <button class="btn btn-primary submit-done" type="button">Done</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-2 d-grid">
                <button class="btn btn-primary" type="submit">Search</button>
              </div>
            </div>
          </form>
              </div>
              <section class="section px-3 px-md-5 pb-4">
        <h2 class="text-9 fw-600 text-center">Why Book Flight with TripRecharge</h2>
        <p class="lead mb-5 text-center">Book Flight Tickets Online. Save Time and Money!</p>
        <div class="row g-4">
          <div class="col-md-4">
            <div class="featured-box text-center">
              <div class="featured-box-icon text-primary"> <i class="fas fa-rupee-sign"></i> </div>
              <h3>No Hidden Charges</h3>
              <p>No hidden charges, no payment fees, and free customer service. So you get the best deal every time!</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="featured-box text-center">
              <div class="featured-box-icon text-primary"> <i class="fas fa-percentage"></i> </div>
              <h3>Cheapest Price</h3>
              <p>Always get cheapest price with the best in the industry. So you get the best deal every time.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="featured-box text-center">
              <div class="featured-box-icon text-primary"> <i class="far fa-times-circle"></i> </div>
              <h3>Easy Cancellation &amp; Refunds</h3>
              <p>Get instant refund and get any booking fees waived off!</p>
            </div>
          </div>
        </div>
      </section>
            </div>
            {/* Flights Tab Start */}
            </div>
          </section>
        </div>
        <section class="section bg-white shadow-md pt-4 pb-3">
      <div class="container">
        <div class="row">
          <div class="col-sm-6 col-md-3">
            <div class="featured-box text-center">
              <div class="featured-box-icon"> <i class="fas fa-lock"></i> </div>
              <h4>100% Secure Payments</h4>
              <p>Moving your card details to a much more secured place.</p>
            </div>
          </div>
          <div class="col-sm-6 col-md-3">
            <div class="featured-box text-center">
              <div class="featured-box-icon"> <i class="fas fa-thumbs-up"></i> </div>
              <h4>Trust pay</h4>
              <p>100% Payment Protection. Easy Return Policy.</p>
            </div>
          </div>
          <div class="col-sm-6 col-md-3">
            <div class="featured-box text-center">
              <div class="featured-box-icon"> <i class="fas fa-bullhorn"></i> </div>
              <h4>Refer & Earn</h4>
              <p>Invite a friend to sign up and earn up to $100.</p>
            </div>
          </div>
          <div class="col-sm-6 col-md-3">
            <div class="featured-box text-center">
              <div class="featured-box-icon"> <i class="far fa-life-ring"></i> </div>
              <h4>24X7 Support</h4>
              <p>We're here to help. Have a query and need help ? <a href="#">Click here</a></p>
            </div>
          </div>
        </div>
      </div>
    </section>
        <Footer />
      </div>
    </>
  )
}

export default Home;