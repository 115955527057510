import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";

function AboutUs() {
    return(
        <>
          <div id="main-wrapper" className="boxed">
              <Header />
              <section class="page-header page-header bg-tabnav">
               <div class="container">
                 <div class="row align-items-center">
                   <div class="col-md-8">
                      <h1>About Us</h1>
                   </div>
                   <div class="col-md-4">
                      <ul class="breadcrumb justify-content-start justify-content-md-end mb-0">
                         <li><Link to="/">Home</Link></li>
                         <li className="active">About Us</li>
                      </ul>
                   </div>
                 </div>
               </div>
              </section>
              <div id="content">
                 <div class="container">
                    <div class="bg-white shadow-md rounded p-4">
                    <h2 class="text-6">What is TripRecharge?</h2>
                       <p>TripRecharge, product of Xine Technology Solutions Pvt Ltd is an IATA accredited travel company which provides a wide range of travel products to the customers. We provide Domestic and International Ticketing, International Roaming and Data Roaming Solutions, a wide range of Travel accessories to buy for the frequent travellers.</p>
                       <p>We will also provide B2B solutions to other travel partners who involved in Air Ticketing and Data Roaming domain.</p>
                       <div class="row mt-4 mb-2">
                          <div class="col-md-4">
                           <div class="featured-box style-1">
                             <div class="featured-box-icon text-primary"> <i class="far fa-thumbs-up"></i></div>
                             <h3>Why choose Us</h3>
                             <p>TripRecharge is one stop shop for all your travel needs either Domestic or International.</p>
                           </div>
                          </div>
                          <div class="col-md-4">
                           <div class="featured-box style-1">
                             <div class="featured-box-icon text-primary"> <i class="far fa-paper-plane"></i> </div>
                             <h3>Our Vision</h3>
                             <p>Our Vision is to provide a delightful travel experiance to the travellers all around the world.</p>
                           </div>
                          </div>
                          <div class="col-md-4">
                           <div class="featured-box style-1">
                             <div class="featured-box-icon text-primary"> <i class="far fa-eye"></i> </div>
                             <h3>Our Team</h3>
                             <p>We are a small team of people who continously striving to bring better products and services to travellers all over the world.</p>
                           </div>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
              <Footer />
          </div>
        </>
    )
}

export default AboutUs;