import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";

function ContactUs() {
    return(
        <>
          <div id="main-wrapper" className="boxed">
              <Header />
              <section class="page-header page-header bg-tabnav">
               <div class="container">
                 <div class="row align-items-center">
                   <div class="col-md-8">
                      <h1>Contact Us</h1>
                   </div>
                   <div class="col-md-4">
                      <ul class="breadcrumb justify-content-start justify-content-md-end mb-0">
                         <li><Link to="/">Home</Link></li>
                         <li className="active">Contact Us</li>
                      </ul>
                   </div>
                 </div>
               </div>
              </section>
              <div id="content">
                 <div class="container">
                 <div class="row g-4">
                    <div class="col-md-6">
                      <div class="bg-white shadow-md rounded h-100 p-3">
                        <iframe class="h-100 w-100" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d957.4202092463823!2d80.45002327568076!3d16.28812028379507!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTbCsDE3JzE3LjIiTiA4MMKwMjcnMDQuNSJF!5e0!3m2!1sen!2sin!4v1712176807563!5m2!1sen!2sin" allowfullscreen></iframe>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="bg-white shadow-md rounded p-4">
                      <h2 class="text-6 mb-4">Get in touch</h2>
                      <hr class="mx-n4 mb-4" />
                      <div class="featured-box style-1">
                      <div class="featured-box-icon text-primary"> <i class="fas fa-map-marker-alt"></i></div>
                       <h3>Xine Technology Solutions Pvt Ltd</h3>
                         <p>18-22-22<br />Bara Imam Street<br />Guntur<br />Andhra Pradesh, India - 522003</p>
                      </div>
                      <div class="featured-box style-1">
                       <div class="featured-box-icon text-primary"> <i class="fas fa-phone"></i> </div>
                        <h3>Telephone</h3>
                        <p>(+91) 9966378677, (+91) 7093259510</p>
                       </div>
                      <div class="featured-box style-1">
                       <div class="featured-box-icon text-primary"> <i class="fas fa-envelope"></i> </div>
                        <h3>Business Inquiries</h3>
                        <p>info@xinetechsol.com</p>
                      </div>
                    </div>
                 </div>
                 </div>
                 </div>
              </div>
              <Footer />
          </div>
        </>
    )
}

export default ContactUs;