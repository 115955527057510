import { Link } from "react-router-dom";

const Header = () => {
    return(
        <>
         <header id="header">
         <div class="container">
           <div class="header-row">
           <div class="header-column justify-content-start"> 
             <div class="logo me-2 me-lg-3"> 
               <Link to="/" className="d-flex">
                 <img src="images/logo.png" alt="TripRecharge" />
               </Link>
             </div>
           </div>
           <div class="header-column justify-content-end"> 
             <nav class="login-signup navbar navbar-expand">
              <ul class="navbar-nav">
                 <li class="profile"><span class="d-none d-sm-inline-block">Login / Sign up</span> <span class="user-icon ms-sm-2"><i class="fas fa-user"></i></span></li>
               </ul>
              </nav>
           </div>
           </div>
         </div>
         </header>
        </>
    )
}

export default Header;