import { Link } from "react-router-dom";

const Footer = () => {
    return(
      <>
         <footer id="footer" class="bg-white mt-1 pt-4">
            <div class="container">
              <div class="row g-4">
                <div class="col-md-6">
                  <p>Payment</p>
                    <ul class="payments-types">
                      <li><a href="#" target="_blank"> <img data-bs-toggle="tooltip" src="images/payment/visa.png" alt="visa" title="Visa" /></a></li>
                      <li><a href="#" target="_blank"> <img data-bs-toggle="tooltip" src="images/payment/discover.png" alt="discover" title="Discover" /></a></li>
                      <li><a href="#" target="_blank"> <img data-bs-toggle="tooltip" src="images/payment/paypal.png" alt="paypal" title="PayPal" /></a></li>
                      <li><a href="#" target="_blank"> <img data-bs-toggle="tooltip" src="images/payment/american.png" alt="american express" title="American Express" /></a></li>
                      <li><a href="#" target="_blank"> <img data-bs-toggle="tooltip" src="images/payment/mastercard.png" alt="discover" title="Discover" /></a></li>
                    </ul>
                </div>
                <div class="col-md-6 d-flex align-items-md-end flex-column">
                  <p>Keep in touch</p>
                    <ul class="social-icons">
                      <li class="social-icons-facebook"><a data-bs-toggle="tooltip" href="http://www.facebook.com/" title="Facebook"><i class="fab fa-facebook-f"></i></a></li>
                      <li class="social-icons-twitter"><a data-bs-toggle="tooltip" href="http://www.twitter.com/" title="Twitter"><i class="fab fa-twitter"></i></a></li>
                      <li class="social-icons-google"><a data-bs-toggle="tooltip" href="http://www.google.com/" title="Google"><i class="fab fa-google"></i></a></li>
                      <li class="social-icons-linkedin"><a data-bs-toggle="tooltip" href="http://www.linkedin.com/" title="Linkedin"><i class="fab fa-linkedin-in"></i></a></li>
                      <li class="social-icons-youtube"><a data-bs-toggle="tooltip" href="http://www.youtube.com/" title="Youtube"><i class="fab fa-youtube"></i></a></li>
                      <li class="social-icons-instagram"><a data-bs-toggle="tooltip" href="http://www.instagram.com/" title="Instagram"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="footer-copyright">
                 <ul class="nav justify-content-center">
                   <li class="nav-item"> <Link to="/aboutus" className="nav-link">About Us</Link></li>
                   <li class="nav-item"> <Link to="/contactus" className="nav-link">Contact</Link> </li>
                   <li class="nav-item"> <Link to="/support" className="nav-link">Support</Link> </li>
                   <li class="nav-item"> <Link to="/terms" className="nav-link">Terms of Use</Link> </li>
                   <li class="nav-item"> <Link to="/privacy" className="nav-link">Privacy Policy</Link> </li>
                   <li class="nav-item"> <Link to="/cancellation" className="nav-link">Cancellation and Refund Policy</Link> </li>
                 </ul>
                 <p class="copyright-text">Copyright © 2024 <a href="http://xinetechsol.com">Xine Technology Solutions Pvt Ltd</a>. All Rights Reserved.</p>
              </div>
            </div>
         </footer>
      </>
    )
}

export default Footer;