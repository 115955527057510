import { Routes, Route } from "react-router-dom";
import Home from "../Home";
import AboutUs from "../common/aboutus";
import ContactUs from "../common/contactus";
import Terms from "../common/terms";
import Support from "../common/support";
import Privacy from "../common/privacy";
import Cancellation from "../common/cancellation";

function Router() {
    return(
        <Routes>
            <Route path="/" element={
               <Home />
            } />
            <Route path="/aboutus" element={
               <AboutUs />
            } />
            <Route path="/contactus" element={
               <ContactUs />
            } />
            <Route path="/terms" element={
               <Terms />
            } />
            <Route path="/support" element={
               <Support />
            } />
            <Route path="/privacy" element={
               <Privacy />
            } />
            <Route path="/cancellation" element={
               <Cancellation />
            } />
        </Routes>
    )
}

export default Router;